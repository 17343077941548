import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../../i18n/i18n.key';
import { Session } from '../../../model/session.model';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { SignInReq } from '../../../request/auth.request';
import { SecurityManager } from '../../../security/security-manager';
import { AuthService } from '../../../service/auth.service';
import { AuthPageState, AuthPageStateProp } from '../auth.page';
import { useSearchParams } from 'react-router-dom';

const LogInComponent: React.FC<AuthPageStateProp> = ({ updatePageState }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const [searchParams] = useSearchParams();

  const [errors, setErrors] = useState({
    id: '',
    password: '',
  });
  const queryId = searchParams.get('id');
  const queryPassword = searchParams.get('password');

  const [id, setId] = useState(queryId ? queryId : '');
  const [password, setPassword] = useState(queryPassword ? queryPassword : '');

  const signIn = async () => {
    const [data, err] = await AuthService.signIn({
      id: id,
      password: password,
    } as SignInReq);

    if (data && data.refreshToken && data.refreshToken.length > 0) {
      SecurityManager.setSession({
        userId: data.userId,
        refreshToken: data.refreshToken,
      } as Session);
    } else {
      const _errors: any = {};
      _errors.id = 'Please enter valid ID';
      _errors.password = 'Please enter valid password';
      setErrors(_errors);
      setValidated(false);
      setLoading(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (!validator.isLength(id, { min: 4, max: 30 })) {
      _errors.id = 'Please enter valid ID';
    }

    if (!validator.isLength(password, { min: 4, max: 30 })) {
      _errors.password = 'Please enter valid password';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      signIn();
    }
  };

  const formStyle = {
    width: '336px',
    maxWidth: '336px',
  };

  return (
    <div
      className="rounded-16 bg-white p-4 ft-14 ms-lg-5 mt-lg-0 mt-5 login--wrapper text-nero z-1 max-w-332"
      style={formStyle}
    >
      <h5 className="mb-4 text-nero">{t(I18nKey.STUDENT_LOGIN_TEXT)}</h5>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="id" className="mb-3">
            <Form.Label>{t(I18nKey.STUDENT_ID_TEXT)}</Form.Label>

            <InputGroup hasValidation className="mb-3">
              <InputGroup.Text
                className={`bg-transparent border px-2 ${
                  errors.id ? 'border-danger' : ''
                } `}
                id="loginUser"
              >
                <img src="/images/user.svg" height="18" alt="" />
              </InputGroup.Text>
              <Form.Control
                required
                type="text"
                value={id}
                isInvalid={!!errors.id}
                onChange={(e) => setId(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.id ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_YOUR_ID_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid ID
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>{t(I18nKey.PASSWORD_KEY_TEXT)}</Form.Label>
            <InputGroup hasValidation className="mb-3">
              <InputGroup.Text
                className={`bg-transparent border px-2 ${
                  errors.password ? 'border-danger' : ''
                } `}
                id="loginPassword"
              >
                <img src="/images/key.svg" height="18" className="" alt="" />
              </InputGroup.Text>
              <Form.Control
                required
                type="password"
                value={password}
                isInvalid={!!errors.password}
                onChange={(e) => setPassword(e.target.value)}
                className={`ft-14 px-1 shadow-none ${
                  errors.password ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_YOUR_PASSCODE_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid password
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <div className="text-start mt-4">
            <span className="mb-2">Forgot Password?</span>
            <Button
              style={{ backgroundColor: '#FFFFFF', borderWidth: 0 }}
              onClick={() => updatePageState(AuthPageState.SEND_FORGOT_LINK)}
            >
              Click here
            </Button>
          </div>
          <div className="text-center mt-4">
            <Button
              variant="primary"
              type="submit"
              className="text-white ft-14 font-weight-600"
            >
              {t(I18nKey.LOGIN_TEXT)}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default LogInComponent;
