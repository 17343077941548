import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTheme } from '../../../themes/theme.provider';

const CircleProgressComponent = ({
  maxValue,
  actualValue,
  msg,
  strokeColor,
  isColumnView,
}: any) => {
  const { theme } = useTheme();

  return (
    <div
      className={`my-3 bg-white d-flex align-items-center p-3 rounded ${
        isColumnView ? 'flex-column mx-2 flex-1 text-center' : ''
      }`}
    >
      <div className={`d-flex align-items-center`}>
        <div className="circle--progrssbar">
          <CircularProgressbarWithChildren
            value={actualValue}
            maxValue={maxValue}
            styles={{
              path: { stroke: strokeColor },
              trail: {
                stroke: '#F5F2F2',
                strokeLinecap: 'butt',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
            }}
          >
            <div
              style={{
                fontSize: 12,
                marginTop: 0,
                color: theme.themeSecondary,
              }}
            >
              <strong>{actualValue}</strong>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <span className="ms-2 text-dimgray ft-12">/ {maxValue}</span>
      </div>
      <div className={`text-nero ${isColumnView ? 'ms-md-auto' : 'ms-auto'} `}>
        {msg}
      </div>
    </div>
  );
};

export default CircleProgressComponent;
