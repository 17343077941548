import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';

const NotificationComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="ps-2 pe-3 position-relative">
      <Badge
        bg="danger"
        className="rounded-25 position-absolute notification-badge"
      >
        {t(I18nKey.NEW_TEXT)}
      </Badge>
      <img
        src="/images/notification.svg"
        height="30"
        className="notification-img"
        alt=""
      />
    </div>
  );
};

export default NotificationComponent;
