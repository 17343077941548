import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { CandidateGeneralReportRes } from '../../../response/candidate-general-report.response';

export interface ProgressComponentProp {
  candidateGeneralReportRes: CandidateGeneralReportRes;
}

const ProgressComponent: React.FC<ProgressComponentProp> = ({
  candidateGeneralReportRes,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const options = {
    chart: {
      type: 'column',
      height: 100,
      width: 170,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: ['M', 'T', 'W', 'T', 'F'],
      lineColor: 'rgba(0, 0, 0, 0)',
      labels: {
        y: 16,
      },
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stack: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.1,
        pointWidth: 24,
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:11px color:#000000 !important;">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}<br/>',
    },
    series: [
      {
        name: '',
        data: [
          {
            name: 'Monday',
            y: 30,
            color: '#E8F2FF',
          },
          {
            name: 'Tusday',
            y: 40,
            color: '#8E96FF',
          },
          {
            name: 'Wednesday',
            y: 35,
            color: '#E8F2FF',
          },
          {
            name: 'Thrusday',
            y: 25,
            color: '#E8F2FF',
          },
          {
            name: 'Friday',
            y: 10,
            color: '#E8F2FF',
          },
        ],
      },
    ],
  };

  return (
    <div className="d-flex flex-column me-3 mb-lg-2 mb-4">
      <h6 style={{ color: theme.themeSecondary }} className="font-weight-700">
        {t(I18nKey.PROGRESS_TEXT)}
      </h6>
      <div className="border border-skyblue rounded-16 px-2 py-3 d-flex align-items-baseline flex-column h-88 highlight--div-styles">
        <div className="d-flex align-items-center mb-2">
          <img src="/images/clock.svg" height="50" alt="" />
          <span style={{ color: theme.themeSecondary }} className="mx-3">
            {t(I18nKey.HOURS_SPEND_TEXT)}
          </span>
        </div>
        <div className="d-flex mt-auto">
          <div className="d-flex flex-column text-start">
            <h4
              style={{ color: theme.themeSecondary }}
              className="font-weight-600 my-1"
            >
              21.2 {t(I18nKey.HRS_TEXT)}
            </h4>
            <div style={{ color: theme.themeColor }} className="ft-12 mb-2">
              spent last 5 days
            </div>
            <div className="bg-primary px-2 py-1 rounded mt-auto mb-2">
              <img src="/images/direction.svg" height="15" alt="" />
              <span className="ms-2 ft-12 text-white font-weight-600">
                +32.9% from last week
              </span>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <HighchartsReact highcharts={Highcharts} options={options} />
            <span style={{ color: theme.themeColor }} className="ft-12">
              {t(I18nKey.DAILY_ACTIVITY_TEXT)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressComponent;
