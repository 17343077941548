import {
  ListGroup,
  Badge,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { I18nKey } from '../../i18n/i18n.key';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { TestInfoRes } from '../../response/test.response';
import { useTranslation } from 'react-i18next';
import { MiniTopic } from '../../model/mini-topic';
import dayjs from 'dayjs';

export interface TestUiComponentProp {
  isFromProfilePage: boolean;
  testInfoList: TestInfoRes[];
}

const TestUiComponent: React.FC<TestUiComponentProp> = ({
  isFromProfilePage,
  testInfoList,
}) => {
  const { t } = useTranslation();
  const { startTest } = useSSNavigate();

  return (
    <>
      {testInfoList.length > 0 ? (
        <ListGroup as="ul" className="highlight--styles">
          {testInfoList.map((testInfo: TestInfoRes) => {
            return (
              <ListGroup.Item
                as="li"
                className="p-0 mb-2 text-nero bg-transparent border-0 d-flex flex-column list--items"
              >
                <div className="d-flex pt-2 px-1">
                  <div
                    style={{
                      width: isFromProfilePage ? '232px' : 'fit-content',
                      minWidth: '112px',
                    }}
                    className="p-2 border-width-2 border-end border-green test--left-panel"
                  >
                    <div className="text-nero font-weight-600 ft-16 mb-2">
                      {dayjs(testInfo.startDate).format('HH:mm')}{' '}
                      <span className="ft-12 font-weight-500">
                        {dayjs(testInfo.startDate).format('A')}
                      </span>
                    </div>
                    <div className="text-nero font-weight-600 ft-16 mb-2">
                      {testInfo.duration / 60}{' '}
                      <span className="ft-12 font-weight-500">
                        {t(I18nKey.MINS_TEXT)}
                      </span>
                    </div>
                    <div className="text-blue-500 font-weight-600 ft-16 border border-portage rounded px-2 py-1">
                      {testInfo.fullMark}{' '}
                      <span className="ft-12 font-weight-500">
                        {t(I18nKey.MARKS_TEXT)}
                      </span>
                    </div>
                    <div className="border border-green rounded text-success py-1 my-2 px-2 text-center ms-auto">
                      {testInfo.mode}
                    </div>
                  </div>
                  <div className="flex-grow-1 p-2">
                    <div className="d-flex align-items-center">
                      <span>{testInfo.name.en}</span>
                    </div>
                    <div className="mt-2">
                      {testInfo.topicList?.map((topic: MiniTopic) => {
                        return (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={topic.id}>{topic.name.en}</Tooltip>
                            }
                          >
                            <Badge
                              bg="lightpink"
                              className="text-dimgray px-3 py-2 font-weight-600 me-2 mb-2"
                            >
                              {topic.name.en}
                            </Badge>
                          </OverlayTrigger>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mt-2 d-flex align-items-center begin--exam-wrapper p-2">
                  {dayjs().second() - dayjs(testInfo.startDate).second() >=
                    60 && (
                    <span className="text-danger mx-2">
                      {(dayjs().second() - dayjs(testInfo.startDate).second()) /
                        60}{' '}
                      Mins left
                    </span>
                  )}
                  <Button
                    onClick={() => {
                      startTest(testInfo.id);
                    }}
                    variant="secondary"
                    className="ms-auto px-3 py-2 rounded ft-14 font-weight-500"
                  >
                    {t(I18nKey.BEGIN_EXAM_TEXT)}
                  </Button>
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : (
        <h6>No scheduled Test Found!</h6>
      )}
    </>
  );
};

export default TestUiComponent;
