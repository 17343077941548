import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';

const LeftComponent = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <img
        src="/images/circle-1.svg"
        height="380"
        className="login-circle-1 position-absolute"
        alt=""
      />
      <img
        src="/images/circle-2.svg"
        height="200"
        className="login-circle-2 position-absolute"
        alt=""
      />
      <img
        src="/images/circle-4.svg"
        height="450"
        className="login-circle-4 position-absolute"
        alt=""
      />

      <div className="me-5 position-relative">
        <div className="d-flex justify-content-center flex-column p-5 rounded-16 position-relative text-nero left--panel">
          <h3 className=" font-weight-600">
            {t(I18nKey.DONOT_WAIT_LEARN_TEXT)}
          </h3>
          <h3 className=" font-weight-600">
            {t(I18nKey.GRAB_OPPORTUNITY_NOW_TEXT)}
          </h3>

          <div className="text-center mt-5 mb-b pt-4 font-weight-600">
            <img src="/images/sassy-dark.svg" height="60" alt="" />
          </div>
        </div>
        <img
          src="/images/trophy.svg"
          height="180"
          className="trophy-img position-absolute"
          alt=""
        />
        <img
          src="/images/certificate.svg"
          height="170"
          className="certificate-img position-absolute"
          alt=""
        />
        <img
          src="/images/graduate-cap.svg"
          height="170"
          className="cap-img position-absolute"
          alt=""
        />
        <img
          src="/images/book.svg"
          height="180"
          className="book-img position-absolute"
          alt=""
        />
        <img
          src="/images/winner-certificate.svg"
          height="100"
          className="winner-img position-absolute"
          alt=""
        />
        <img
          src="/images/circle-3.svg"
          height="140"
          className="login-circle-3 position-absolute"
          alt=""
        />
      </div>
    </React.Fragment>
  );
};

export default LeftComponent;
