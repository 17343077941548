import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageHelper } from './lang.helper';

export const useI18n = () => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState<string>(
    LanguageHelper.getLanguage(),
  );

  useEffect(() => {
    i18n.changeLanguage(language);
    LanguageHelper.setLanguage(language);
  }, [language]);

  return { language, setLanguage };
};
