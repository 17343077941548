import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import {
  SubjectAnalysis,
  TopicAnalysis,
} from '../../../response/candidate-general-report.response';
import SubjectPerformanceComponent from './subject-performance.component';
import SubjectPerformanceChartComponent from './subject-performance-chart.component';

export interface SubjectWiseAnalysisComponentProp {
  topicAnalysisList: TopicAnalysis[];
  subjectAnalysisList: SubjectAnalysis[];
}

const SubjectWiseAnalysisComponent: React.FC<
  SubjectWiseAnalysisComponentProp
> = ({ subjectAnalysisList, topicAnalysisList }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="d-flex align-items-center">
        <h6 className="font-weight-700 mb-0 text-nero">
          {t(I18nKey.PROGRESS_CHART_TEXT)}
        </h6>
        <div className="ms-auto">
          <img src="/images/info-icon.svg" alt="" />
        </div>
      </div>

      <Row className="mt-3">
        {subjectAnalysisList.map((subjectAnalysis: SubjectAnalysis) => {
          const subjectTopicAnalysisList = topicAnalysisList?.filter(
            (topicAnalysis: TopicAnalysis) => {
              return topicAnalysis.subjectId == subjectAnalysis.subject.id;
            },
          );
          return (
            <Col md={6}>
              <div className="bg-off-white p-3 rounded mb-3">
                <SubjectPerformanceChartComponent
                  title={subjectAnalysis.subject.name.en}
                  iconName={'physics.svg'}
                  topicAnalysisList={subjectTopicAnalysisList}
                />
                <SubjectPerformanceComponent
                  subjectAnalysis={subjectAnalysis}
                  topicAnalysisList={subjectTopicAnalysisList}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default SubjectWiseAnalysisComponent;
