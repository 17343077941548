import { Button, ListGroup } from 'react-bootstrap';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { useEffect, useState } from 'react';
import { CandidateTestReportService } from '../../../service/candidate-test-report.service';
import { CandidateTestReportSearchReq } from '../../../request/candidate-test-report.request';
import { CandidateTestReportInfoRes } from '../../../response/candidate-test-report.response';
import dayjs from 'dayjs';
import LoaderComponent from '../../shared/loader.component';

const ResultComponent = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { openTestDetail } = useSSNavigate();

  const [loading, setLoading] = useState(true);

  const [candidateReportInfoResList, setCandidateReportInfoResList] = useState(
    [],
  );

  const findReport = async () => {
    const [data, error] = await CandidateTestReportService.search({
      itemsPerPage: 20,
      pageNumber: 1,
    } as CandidateTestReportSearchReq);
    if (error) {
    } else {
      setCandidateReportInfoResList(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    findReport();
  }, []);

  return (
    <div className="bg-off-white text-nero p-3 rounded h-100">
      <div className="d-flex align-items-center">
        <h6 className="me-2 ft-16 mb-0 font-weight-700">
          {t(I18nKey.RESULT_TEXT)}
        </h6>
      </div>
      {loading ? (
        <LoaderComponent />
      ) : (
        <div className="my-3">
          {candidateReportInfoResList.length > 0 ? (
            <ListGroup as="ul" className={`highlight--styles result--ul`}>
              {candidateReportInfoResList.map(
                (candidateTestReportInfoRes: CandidateTestReportInfoRes) => {
                  return (
                    <ListGroup.Item
                      as="li"
                      className="px-3 py-2 mb-2 text-nero bg-transparent border-0 d-flex flex-column list--items"
                    >
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center w-100">
                          <span>
                            {candidateTestReportInfoRes.test.name?.en}
                          </span>
                          <div className="ms-auto d-flex align-items-center">
                            <span className="ft-12 text-dimgray mx-3">
                              {dayjs(
                                candidateTestReportInfoRes.createdOn,
                              ).format('DD[th] YYYY')}
                            </span>
                            <div className="border border-green rounded text-success py-1 px-2 text-center">
                              {candidateTestReportInfoRes.test?.mode}
                            </div>
                          </div>
                        </div>
                        <div className="mt-1 scored--wrapper">
                          <div className="d-flex align-items-center">
                            <div className="circle--progrssbar">
                              <CircularProgressbarWithChildren
                                value={Number(
                                  (
                                    (candidateTestReportInfoRes.testAnalysis
                                      .score.scored /
                                      candidateTestReportInfoRes.testAnalysis
                                        .score.full) *
                                    100
                                  ).toFixed(2),
                                )}
                                styles={{
                                  path: { stroke: `rgb(97, 149, 237)` },
                                  trail: {
                                    stroke: '#F5F2F2',
                                    strokeLinecap: 'butt',
                                    transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                  },
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 12,
                                    marginTop: 0,
                                    color: theme.themeSecondary,
                                  }}
                                >
                                  <strong>
                                    {
                                      candidateTestReportInfoRes.testAnalysis
                                        .score.scored
                                    }
                                  </strong>
                                </div>
                              </CircularProgressbarWithChildren>
                            </div>
                            <span className="ft-12 mx-2">
                              {t(I18nKey.OF_TEXT)}{' '}
                              {
                                candidateTestReportInfoRes.testAnalysis.score
                                  .full
                              }{' '}
                              <strong className="ft-14">
                                {t(I18nKey.SCORED_TEXT)}
                              </strong>{' '}
                            </span>

                            <Button
                              onClick={() => {
                                openTestDetail(candidateTestReportInfoRes.id);
                              }}
                              variant="french-pass"
                              className="ms-auto px-3 py-1 rounded ft-14 text-darkblue font-weight-500"
                            >
                              {t(I18nKey.VIEW_DETAILS_TEXT)}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </ListGroup.Item>
                  );
                },
              )}
            </ListGroup>
          ) : (
            <h6>No result found, Please attempt one test</h6>
          )}
        </div>
      )}
    </div>
  );
};

export default ResultComponent;
