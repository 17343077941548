import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import YetCompleteComponent from '../../shared/yet-complete.component';
import { useState, useEffect } from 'react';
import { TestService } from '../../../service/test.service';
import LoaderComponent from '../../shared/loader.component';

const YetToCompleteTestComponent = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [testInfoList, setTestInfoList] = useState([]);

  const findUpcomingTest = async () => {
    const [data, error] = await TestService.ongoingTest();
    if (error) {
    } else {
      setTestInfoList(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    findUpcomingTest();
  }, []);

  return (
    <div className="bg-light-purple text-nero p-3 rounded h-100">
      <div className="d-flex align-items-center">
        <h6 className="me-2 ft-16 mb-0 font-weight-700">
          {t(I18nKey.YET_TO_COMPLETE_TEXT)}
        </h6>
        <span className="ft-12">{testInfoList.length}</span>
      </div>
      <div className="my-3">
        {loading ? (
          <LoaderComponent />
        ) : (
          <YetCompleteComponent testInfoList={testInfoList} />
        )}
      </div>
    </div>
  );
};

export default YetToCompleteTestComponent;
