import { Outlet } from 'react-router';
import { useWindow } from '../util/window-helper';
import FooterComponent from './shared/footer.component';
import HeaderComponent from './shared/header/header.component';
import { useSSNavigate } from '../navigation/ss-navigate.hook';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { AuthService } from '../service/auth.service';
import { SecurityManager } from '../security/security-manager';

const MainHolder = () => {
  const { isMobile } = useWindow();
  const { openNoPlan } = useSSNavigate();
  const [loading, setLoading] = useState(true);

  const loggedIn = SecurityManager.loggedIn();

  const validateUser = async () => {
    if (!loggedIn) {
      openNoPlan();
      return;
    }
    const [data, err] = await AuthService.getAuthToken();
    if (data && data.authToken && data.authToken.length > 0) {
      SecurityManager.updateAuthToken(data.authToken);
      setLoading(false);
    } else {
      SecurityManager.logout();
    }
  };

  useEffect(() => {
    validateUser();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <>
          <HeaderComponent />
          <div style={{ paddingTop: 80, minHeight: '94vh' }}>
            <Outlet />
          </div>

          {!isMobile && <FooterComponent />}
        </>
      )}
    </>
  );
};

export default MainHolder;
