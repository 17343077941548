import { Button, Col, Row } from 'react-bootstrap';
import EnrollmentDetailsComponent from './component/enrollment-details.component';
import ProfileDetailsComponent from './component/profile-details.component';
import { CandidateService } from '../../service/candidate.service';
import { useState, useEffect } from 'react';
import { CandidateSearchReq } from '../../request/candidate.request';
import { CandidateRes } from '../../response/candidate.response';
import LoaderComponent from '../shared/loader.component';
import { MiniProgram } from '../../model/mini-program.model';

const ProfilePageComponent = () => {
  const [candidateRes, setCandidateRes] = useState<CandidateRes>({} as any);
  const [loading, setLoading] = useState(true);

  const getCandidate = async () => {
    const [data, error] = await CandidateService.config();
    if (error) {
    } else {
      setCandidateRes(data.candidate);
    }
  };

  useEffect(() => {
    getCandidate();
  }, []);

  return (
    <>
      {loading ? (
        <LoaderComponent />
      ) : (
        <div className="py-3">
          <div className="d-flex flex-column align-items-center justify-content-center mb-5">
            <div className="user-profile--section rounded-circle position-relative">
              <img src="/images/profile.svg" alt="" className="w-100 h-100" />
              <div className="user-img--wrapper">
                <input type="file" className="user-file-input" />
                <Button variant="default" className="p-0 user-img--edit">
                  <img src="/images/edit.svg" height={18} alt="" />
                </Button>
              </div>
            </div>

            <span className="bg-gray rounded text-nero px-2 py-1 ft-12 my-2">
              @{candidateRes.enrollmentId}
            </span>
            <div className="bg-warning px-3 rounded py-2 mt-1">
              <img src="/images/programme.svg" height="15" alt="" />
              <span className="ms-2 ft-12 text-nero font-weight-600">
                {candidateRes?.programList
                  ?.map((program: MiniProgram) => {
                    return program.name.en;
                  })
                  .join(',')}
              </span>
            </div>
          </div>

          <Row className="mx-md-2 mx-1">
            <Col md={6} xs={12}>
              <ProfileDetailsComponent candidateRes={candidateRes} />
            </Col>
            <Col md={6} xs={12}>
              <EnrollmentDetailsComponent candidateRes={candidateRes} />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ProfilePageComponent;
