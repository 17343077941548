import { CandidateSearchReq } from '../request/candidate.request';
import { CandidateConfig, CandidateRes } from '../response/candidate.response';
import httpClient from '../util/http-client';

export const CandidateService = {
  search: async (
    candidateSearchReq: CandidateSearchReq,
  ): Promise<(CandidateRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(
        '/candidate-search',
        candidateSearchReq,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  config: async (): Promise<(CandidateConfig | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get('/candidate-config');
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
