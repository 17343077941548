import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { TestAnalysis } from '../../../response/candidate-test-report.response';
import dayjs from 'dayjs';

export interface TestSummaryComponentProp {
  testAnalysis: TestAnalysis;
  mode: string;
  createdOn: Date;
}

const TestSummaryComponent: React.FC<TestSummaryComponentProp> = ({
  testAnalysis,
  mode,
  createdOn,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <div className="d-flex flex-column">
      <h6 style={{ color: theme.themeSecondary }} className="font-weight-700">
        {t(I18nKey.TEST_SUMMARY_TEXT)}
      </h6>
      <div className="border border-skyblue rounded-16 px-3 py-3 d-flex align-items-baseline flex-column">
        <div className="d-flex align-items-center ft-14 w-100 border-bottom border-light-skyblue pb-2">
          <img src="/images/calender.svg" alt=""></img>
          <span
            style={{ color: theme.themeSecondary }}
            className="mx-2 font-weight-600"
          >
            {t(I18nKey.DATE_TEXT)}
          </span>
          <span style={{ color: theme.themeColor }} className="ms-auto">
            {dayjs(createdOn).format('DD MMM YYYY')}
          </span>
        </div>
        <div className="d-flex align-items-center ft-14 w-100 border-bottom border-light-skyblue py-2">
          <img src="/images/time.svg" alt=""></img>
          <span
            style={{ color: theme.themeSecondary }}
            className="mx-2 font-weight-600"
          >
            {t(I18nKey.TIMING_TEXT)}
          </span>
          <span style={{ color: theme.themeColor }} className="ms-auto">
            {dayjs(createdOn).format('HH:MM A')}
          </span>
        </div>
        <div className="d-flex align-items-center ft-14 w-100 border-bottom border-light-skyblue py-2">
          <img src="/images/duration.svg" alt=""></img>
          <span
            style={{ color: theme.themeSecondary }}
            className="mx-2 font-weight-600"
          >
            {t(I18nKey.DURATION_TEXT)}
          </span>
          <span style={{ color: theme.themeColor }} className="ms-auto">
            {testAnalysis.duration / 60} mins
          </span>
        </div>
        <div className="d-flex align-items-center ft-14 w-100 border-bottom border-light-skyblue py-2">
          <img src="/images/duration.svg" alt=""></img>
          <span
            style={{ color: theme.themeSecondary }}
            className="mx-2 font-weight-600"
          >
            {t(I18nKey.TIME_TAKEN_TEXT)}
          </span>
          <span style={{ color: theme.themeColor }} className="ms-auto">
            {(testAnalysis.timeTaken / 60).toFixed(0)} mins{' '}
            {testAnalysis.timeTaken % 60} secs
          </span>
        </div>
        <div className="d-flex align-items-center ft-14 w-100 pt-2 pb-1">
          <img src="/images/mode.svg" alt=""></img>
          <span
            style={{ color: theme.themeSecondary }}
            className="mx-2 font-weight-600"
          >
            {t(I18nKey.MODE_TEXT)}
          </span>
          <div className="border border-green rounded text-success py-1 px-2 text-center ms-auto">
            {mode}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSummaryComponent;
