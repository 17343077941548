import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { TestTaken } from '../../../response/candidate-general-report.response';
import { I18nKey } from '../../../i18n/i18n.key';

export interface PerformanceComponentProp {
  testTakenList: TestTaken[];
}

const PerformanceComponent: React.FC<PerformanceComponentProp> = ({
  testTakenList,
}) => {
  const { t } = useTranslation();

  const options = {
    chart: {
      type: 'column',
      height: 120,
      style: {
        fontFamily: 'Montserrat',
        color: '#676767',
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'category',
      lineColor: 'rgba(0, 0, 0, 0)',
      labels: {
        y: 16,
        style: {
          color: '#676767',
        },
      },
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stack: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.1,
        pointWidth: 24,
      },
    },
    tooltip: {
      headerFormat: `<span style="font-size:11px !important;">{series.name}</span><br>`,
      pointFormat: `<span style="color:#222222">{point.name}</span>: <b>{point.y}<br/>`,
    },
    series: [
      {
        name: '',
        data: testTakenList.map((testTaken: TestTaken, index: number) => {
          return {
            name: `${index + 1}th`,
            y: Number(
              ((testTaken.score.scored / testTaken.score.full) * 100).toFixed(
                2,
              ),
            ),
            color: '#E8F2FF',
          };
        }),
      },
    ],
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center report-performance--section">
        <HighchartsReact highcharts={Highcharts} options={options} />
        <span className="ft-12 text-nero pb-3">
          {t(I18nKey.PREVIOUS_SCORE_TEXT)}
        </span>
      </div>
    </>
  );
};

export default PerformanceComponent;
