import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import PerformanceChartComponent from './performance-chart.component';
import ScoreChartComponent from './score-chart.component';
import {
  CandidateTestReportRes,
  SectionAnalysis,
} from '../../../response/candidate-test-report.response';
import { TestSummaryReportRes } from '../../../response/test-summary-report.response';

export interface TestResultComponentProp {
  candidateTestReportRes: CandidateTestReportRes;
  testSummaryReportRes: TestSummaryReportRes;
}

const TestResultComponent: React.FC<TestResultComponentProp> = ({
  candidateTestReportRes,
  testSummaryReportRes,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <div className="d-flex flex-column lg:me-2 me-1 h-100">
      <h6 style={{ color: theme.themeSecondary }} className="font-weight-700">
        {t(I18nKey.YOUR_TEST_RESULT_KEY)}
      </h6>
      <div className="border border-skyblue rounded-16 lg:px-2 px-2 py-1 d-flex align-items-baseline flex-column flex-grow-1">
        <Row className="w-100">
          <Col md={4} className="mb-md-0 mb-3">
            <div className="d-flex align-items-center ft-14 border-bottom border-light-skyblue pb-1 w-100">
              <span
                style={{ color: theme.themeSecondary }}
                className="mx-2 font-weight-600"
              >
                {t(I18nKey.TOTAL_TEXT)}
              </span>
              <div className="ms-auto">
                <span style={{ color: theme.themeSecondary }} className="ft-24">
                  {candidateTestReportRes.testAnalysis.score.scored}/
                </span>
                <span style={{ color: theme.themeColor }} className="ft-14">
                  {candidateTestReportRes.testAnalysis.score.full}
                </span>
              </div>
            </div>
            {candidateTestReportRes.sectionAnalysisList.map(
              (sectionAnalysis: SectionAnalysis) => {
                return (
                  <div className="d-flex align-items-center flex-column ft-14 border-bottom border-light-skyblue py-1 w-100">
                    <div className="d-flex align-items-center w-100">
                      <span className="circle-dot bg-turquoiseblue"></span>
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="mx-2 font-weight-600"
                      >
                        {sectionAnalysis.name.en}
                      </span>
                      <div className="ms-auto">
                        <span
                          style={{ color: theme.themeSecondary }}
                          className="ft-20"
                        >
                          {sectionAnalysis.score.scored}/
                        </span>
                        <span
                          style={{ color: theme.themeColor }}
                          className="ft-14"
                        >
                          {sectionAnalysis.score.full}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{ color: theme.themeColor }}
                      className="ft-12 text-center"
                    >
                      {sectionAnalysis.question.attempted} {t(I18nKey.OF_TEXT)}{' '}
                      {sectionAnalysis.question.total}{' '}
                      {t(I18nKey.ATTEMPTED_TEXT)}{' '}
                    </div>
                  </div>
                );
              },
            )}
          </Col>
          <Col md={4} className="mb-md-0 mb-3">
            <ScoreChartComponent
              candidateTestReportRes={candidateTestReportRes}
              testSummaryReportRes={testSummaryReportRes}
            />
          </Col>
          <Col md={4} className="mb-md-0 mb-3">
            <PerformanceChartComponent
              isSubject={false}
              highestMarks={testSummaryReportRes.highestScore}
              yourMarks={candidateTestReportRes.testAnalysis.score.scored}
              avgMarks={testSummaryReportRes.averageScore}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TestResultComponent;
