import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n/i18n.key';
import CircleProgressComponent from './component/circle-progress.component';
import { useEffect, useState } from 'react';
import { CandidateGeneralReportService } from '../../service/candidate-general-report.service';
import { CandidateGeneralReportSearchReq } from '../../request/candidate-general-report.request';
import SubjectWiseAnalysisComponent from './component/suject-wise-analysis.component';
import { CandidateGeneralReportRes } from '../../response/candidate-general-report.response';
import PerformanceComponent from './component/performance.component';

const ReportPage = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const [candidateGeneralReportRes, setCandidateGeneralReportRes] =
    useState<CandidateGeneralReportRes>();

  const getGeneralReport = async () => {
    const [data, error] = await CandidateGeneralReportService.search({
      itemsPerPage: 20,
      pageNumber: 1,
    } as CandidateGeneralReportSearchReq);
    if (error) {
    } else {
      if (data && data.length > 0) {
        setCandidateGeneralReportRes(data[0]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getGeneralReport();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <Row className="px-3 py-3 d-flex mx-0">
          {candidateGeneralReportRes ? (
            <>
              <Col md={3} xs={12} className="bg-light-300 rounded-16 p-3">
                <div className="d-flex align-items-center">
                  <h6 className="font-weight-700 mb-0 text-nero">
                    {t(I18nKey.PERFORMANCE_TEXT)}
                  </h6>
                </div>
                <div className="my-3 border border-skyblue rounded-16 bg-white">
                  <div className="px-3 py-2">
                    <h4 className="m-0 text-nero">
                      {(
                        (candidateGeneralReportRes.highestScore.scored /
                          candidateGeneralReportRes.highestScore.full) *
                        100
                      ).toFixed(0)}
                      %
                    </h4>
                    <span className="ft-12 text-dimgray">
                      {t(I18nKey.MARKS_OBTAINED_AVG_TEXT)}
                    </span>
                  </div>
                  <PerformanceComponent
                    testTakenList={candidateGeneralReportRes.testTakenList}
                  />
                </div>

                <div
                  className={`my-3 bg-white d-flex align-items-center p-3 rounded`}
                >
                  <div className={`d-flex align-items-center`}>
                    <h6>
                      <strong>
                        {candidateGeneralReportRes.testTakenList.length}
                      </strong>{' '}
                      {t(I18nKey.CANTER_EXAM_TAKEN_TEXT)}
                    </h6>
                  </div>
                </div>

                <CircleProgressComponent
                  maxValue={candidateGeneralReportRes.highestScore.full}
                  actualValue={candidateGeneralReportRes.highestScore.scored}
                  msg={t(I18nKey.HIGHEST_SCORE_TEXT)}
                  strokeColor={'rgb(142, 150, 255)'}
                />

                <CircleProgressComponent
                  maxValue={candidateGeneralReportRes.lowestScore.full}
                  actualValue={candidateGeneralReportRes.lowestScore.scored}
                  msg={t(I18nKey.LOWEST_SCORE_TEXT)}
                  strokeColor={'rgb(229, 114, 129)'}
                />

                <CircleProgressComponent
                  maxValue={candidateGeneralReportRes.lastScore.full}
                  actualValue={candidateGeneralReportRes.lastScore.scored}
                  msg={t(I18nKey.LAST_SCORE_TEXT)}
                  strokeColor={'rgb(229, 114, 129)'}
                />
              </Col>

              <Col md={9} xs={12}>
                <div className="ms-md-3 pt-3">
                  <SubjectWiseAnalysisComponent
                    subjectAnalysisList={
                      candidateGeneralReportRes.subjectAnalysisList
                    }
                    topicAnalysisList={
                      candidateGeneralReportRes.topicAnalysisList
                    }
                  />
                </div>
              </Col>
            </>
          ) : (
            <h1>No report found!</h1>
          )}
        </Row>
      )}
    </>
  );
};

export default ReportPage;
