import { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import HeaderMenuComponent from './header-menu.component';
import NotificationComponent from './notification.component';

const HeaderMobileComponent = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" className="py-2" onClick={handleShow}>
        <img
          src="/images/burger-white.svg"
          className="mtm-3"
          height={16}
          alt=""
        />
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="mbl-header--canvas w-80"
      >
        <Offcanvas.Header className="pt-4 border-bottom border-light-200 mx-4 px-0">
          <Button
            variant="aliceblue"
            className="py-2 border-primary"
            onClick={handleClose}
          >
            <img
              src="/images/burger-purple.svg"
              className="mtm-3"
              height={16}
              alt=""
            />
          </Button>
          <NotificationComponent />
        </Offcanvas.Header>
        <Offcanvas.Body className="mx-3 d-flex flex-column">
          <HeaderMenuComponent setShow={setShow} />
          <div className="mt-auto py-4 d-flex align-items-center">
            <span style={{ color: theme.themeSecondary }} className="me-1">
              {t(I18nKey.POWRED_BY_TEXT)}
            </span>
            <img src="/images/logo-light.svg" alt="" />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default HeaderMobileComponent;
