import { useNavigate } from 'react-router';
import { TestService } from '../service/test.service';
import { TestTokenReq } from '../request/test.request';
import { SecurityManager } from '../security/security-manager';

export const useSSNavigate = () => {
  const navigate = useNavigate();

  const openAuth = () => {
    navigate('/auth', { replace: true });
  };

  const openNoPlan = () => {
    navigate('/no-plan-found', { replace: true });
  };

  const openHome = () => {
    navigate(`/`, { replace: true });
  };

  const openReport = () => {
    navigate(`/report`, { replace: false });
  };

  const openTest = () => {
    navigate(`/test`, { replace: false });
  };

  const openTestDetail = (id: string) => {
    navigate(`/test/${id}`, { replace: false });
  };

  const openProfile = () => {
    navigate(`/profile`, { replace: false });
  };

  const startTest = async (testId: string) => {
    const testTokenReq = {
      testId: testId,
      userId: SecurityManager.getUid(),
    } as TestTokenReq;

    const testDomain = process.env.REACT_APP_TEST_DOMAIN;
    const [data, err] = await TestService.getAccessToken(testTokenReq);
    if (data && data.token && data.token.length > 0) {
      const testUrl = `${testDomain}?token=${data.token}&userId=${data.userId}&testId=${data.testId}`;
      console.log(testUrl);
      window.open(testUrl, '_blank');
    } else {
    }
  };

  return {
    openAuth,
    openNoPlan,
    openHome,
    openReport,
    openTest,
    openTestDetail,
    openProfile,
    startTest,
  };
};
