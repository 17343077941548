import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import CircleProgressComponent from './circle-progress.component';
import {
  SubjectAnalysis,
  TopicAnalysis,
} from '../../../response/candidate-general-report.response';
import React from 'react';

export interface SubjectPerformanceComponentProp {
  subjectAnalysis: SubjectAnalysis;
  topicAnalysisList: TopicAnalysis[];
}

const SubjectPerformanceComponent: React.FC<
  SubjectPerformanceComponentProp
> = ({ subjectAnalysis, topicAnalysisList }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="my-2 bg-white p-3 d-flex align-items-center rounded flex-md-row flex-column">
        <div className="font-weight-700 mb-0 text-nero ft-12">
          {t(I18nKey.AREA_OF_IMPORVMENT_TEXT)}:
        </div>
        <div className="d-flex align-items-center mx-2 flex-wrap">
          {topicAnalysisList
            .filter((topicAnalysis: TopicAnalysis) => {
              return topicAnalysis.level > 0.5;
            })
            .map((topicAnalysis: TopicAnalysis) => {
              return (
                <Badge
                  bg="lightpink"
                  className="text-dimgray px-2 py-2 font-weight-600 me-2 mb-2"
                >
                  {topicAnalysis.topic.name.en}
                </Badge>
              );
            })}
        </div>
      </div>

      <div className="my-2 bg-white p-3 d-flex align-items-center rounded flex-md-row flex-column">
        <div className="font-weight-700 mb-0 text-nero ft-12">
          {t(I18nKey.BEST_PERFORMING_AREA_TEXT)}:
        </div>
        <div className="d-flex align-items-center mx-2 flex-wrap">
          {topicAnalysisList
            .filter((topicAnalysis: TopicAnalysis) => {
              return topicAnalysis.level <= 0.5;
            })
            .map((topicAnalysis: TopicAnalysis) => {
              return (
                <Badge
                  bg="lightpink"
                  className="text-dimgray px-2 py-2 font-weight-600 me-2 mb-2"
                >
                  {topicAnalysis.topic.name.en}
                </Badge>
              );
            })}
        </div>
      </div>

      <div className="d-flex rounded flex-md-row flex-column text-center">
        <CircleProgressComponent
          maxValue={subjectAnalysis.highestScore.full}
          actualValue={subjectAnalysis.highestScore.scored}
          msg={t(I18nKey.HIGHEST_TILL_TODAY_TEXT)}
          strokeColor={'rgb(102, 176, 240)'}
          isColumnView={true}
        />

        <CircleProgressComponent
          maxValue={subjectAnalysis.averageScore.full.toFixed(2)}
          actualValue={subjectAnalysis.averageScore.scored.toFixed(2)}
          msg={t(I18nKey.AVG_SCORE_TEXT)}
          strokeColor={'rgb(255, 207, 45)'}
          isColumnView={true}
        />

        <CircleProgressComponent
          maxValue={subjectAnalysis.lastScore.full}
          actualValue={subjectAnalysis.lastScore.scored}
          msg={t(I18nKey.SCORE_LAST_TEST_TEXT)}
          strokeColor={'rgb(72, 189, 85)'}
          isColumnView={true}
        />
      </div>
    </>
  );
};

export default SubjectPerformanceComponent;
