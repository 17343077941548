import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  CandidateTestReportRes,
  SectionAnalysis,
} from '../../../response/candidate-test-report.response';
import {
  SectionSummary,
  TestSummaryReportRes,
} from '../../../response/test-summary-report.response';

export interface ScoreChartComponentProp {
  candidateTestReportRes: CandidateTestReportRes;
  testSummaryReportRes: TestSummaryReportRes;
}

const ScoreChartComponent: React.FC<ScoreChartComponentProp> = ({
  candidateTestReportRes,
  testSummaryReportRes,
}) => {
  const options = {
    chart: {
      type: 'column',
      width: 310,
      height: 205,
    },
    title: {
      text: 'Score Chart',
      style: {
        fontSize: '16px',
      },
    },
    xAxis: {
      categories: candidateTestReportRes.sectionAnalysisList.map(
        (sectionAnalysis: SectionAnalysis) => {
          return sectionAnalysis.name.en;
        },
      ),
      labels: {
        y: 16,
      },
    },
    yAxis: {
      title: '',
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stack: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.2,
        pointWidth: 32,
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:11px color:#000000 !important;">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}<br/>',
    },
    series: [
      {
        name: 'Scored',
        data: candidateTestReportRes.sectionAnalysisList.map(
          (sectionAnalysis: SectionAnalysis) => {
            return {
              // name: sectionAnalysis.name.en,
              y: sectionAnalysis.score.scored,
              color: '#9747FF',
            };
          },
        ),
      },
      {
        name: 'Full Marks',
        data: candidateTestReportRes.sectionAnalysisList.map(
          (sectionAnalysis: SectionAnalysis) => {
            return {
              // name: sectionAnalysis.name.en,
              y: sectionAnalysis.score.full,
              color: '#BACAFF',
            };
          },
        ),
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ScoreChartComponent;
