import { Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import HeaderMobileComponent from './header-mbl.component';
import HeaderMenuComponent from './header-menu.component';
import NotificationComponent from './notification.component';
import { SecurityManager } from '../../../security/security-manager';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import dayjs from 'dayjs';

const HeaderComponent = () => {
  const { theme } = useTheme();
  const { isMobile } = useWindow();

  const { openHome, openProfile, openReport, openTest } = useSSNavigate();

  return (
    <>
      <header
        style={{ background: theme.themePrimary }}
        className="px-4 py-3 border-bottom border-light-200 d-flex align-items-center fixed-top"
      >
        {isMobile && <HeaderMobileComponent />}
        {!isMobile && <HeaderMenuComponent />}

        <div className="ms-auto d-flex align-items-center">
          <div style={{ color: theme.themeColor }} className="small mx-3">
            {dayjs().format('DD[th] MMMM, YYYY')}
          </div>
          {false && <NotificationComponent />}

          <Dropdown as={ButtonGroup} className="d-inline mx-2 bg-aliceblue">
            <Button onClick={openProfile} variant="aliceblue" className="px-3">
              <img src="/images/profile.svg" height={32} alt="" />
            </Button>
            <Dropdown.Toggle
              split
              variant="aliceblue"
              id="dropdown-split-basic"
            />

            <Dropdown.Menu>
              <Dropdown.Item onClick={openHome}>Home</Dropdown.Item>
              <Dropdown.Item onClick={openTest}>Test</Dropdown.Item>
              <Dropdown.Item onClick={openReport}>Report</Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  SecurityManager.logout();
                }}
              >
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
    </>
  );
};

export default HeaderComponent;
