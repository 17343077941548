import { CacheKey } from '../enum/cache-key.enum';

export const LanguageHelper = {
  setLanguage: (lang: string) => {
    try {
      localStorage.setItem(CacheKey.USER_LANGUAGE, lang);
    } catch (err) {
      console.error(err);
    }
  },
  getLanguage: (): string => {
    try {
      const lang = localStorage.getItem(CacheKey.USER_LANGUAGE) || 'en';
      if (lang && lang.length > 0) {
        return lang;
      } else {
        return 'en';
      }
    } catch (err) {
      console.error(err);
      return 'en';
    }
  },
};
