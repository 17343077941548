import { useEffect, useState } from 'react';
import { FontSize } from './font.type';

export const useFont = () => {
  const [fontSize, setFontSize] = useState<FontSize>(FontSize.NORMAL);

  useEffect(() => {
    const body: any = document.querySelector('body');
    body.parentElement.style.fontSize = fontSize;
  }, [fontSize]);

  return { fontSize, setFontSize };
};
