import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { CandidateRes } from '../../../response/candidate.response';
import { MiniExam } from '../../../model/mini-exam';
import { MiniSubject } from '../../../model/mini-subject';
import { MiniCenter } from '../../../model/mini-center.model';
import dayjs from 'dayjs';

export interface EnrollmentDetailsComponentProp {
  candidateRes: CandidateRes;
}

const EnrollmentDetailsComponent: React.FC<EnrollmentDetailsComponentProp> = ({
  candidateRes,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <div className="d-flex flex-column h-100">
      <h6
        style={{ color: theme.themeSecondary }}
        className="font-weight-700 mb-3"
      >
        <img src="/images/enm-detail.svg" height={48} alt="" />
        <span className="mx-2">{t(I18nKey.ENROLLMENTS_DETAILS_TEXT)}</span>
      </h6>
      <div className="border border-skyblue rounded-16 py-3 px-4 font-weight-600 flex-grow-1">
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.PACKAGE_TEXT)}:
          </Col>
          <Col md={10} xs={8} style={{ color: theme.themeSecondary }}>
            Thongjam
          </Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.FORM_ID_TEXT)}:
          </Col>
          <Col md={10} xs={8} style={{ color: theme.themeSecondary }}>
            BZPQ62315845
          </Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.COURSE_TEXT)}:
          </Col>
          <Col md={10} xs={8} style={{ color: theme.themeSecondary }}>
            {candidateRes?.examList
              ?.map((exam: MiniExam) => {
                return exam.name.en;
              })
              .join(',')}
          </Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.STREAM_TEXT)}:
          </Col>
          <Col md={10} xs={8} style={{ color: theme.themeSecondary }}>
            {candidateRes?.subjectList
              ?.map((subject: MiniSubject) => {
                return subject.name.en;
              })
              .join(',')}
          </Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.CENTER_TEXT)}:
          </Col>
          <Col md={10} xs={8} style={{ color: theme.themeSecondary }}>
            {candidateRes?.centerList
              ?.map((center: MiniCenter) => {
                return center.name.en;
              })
              .join(',')}
          </Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.DATE_OF_ENROLLMENT_TEXT)}:
          </Col>
          <Col md={10} xs={8} style={{ color: theme.themeSecondary }}>
            {dayjs(candidateRes.createdOn).format('DD[th] MMMM, YYYY')}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EnrollmentDetailsComponent;
