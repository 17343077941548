import { CacheKey } from '../enum/cache-key.enum';
import { Session } from '../model/session.model';

const getSession = (): Session => {
  const session = JSON.parse(
    localStorage.getItem(CacheKey.USER_SESSION) || '{}',
  ) as Session;
  return session;
};

export const SecurityManager = {
  loggedIn: (): boolean => {
    let session = getSession();

    if (session && session.refreshToken && session.refreshToken.length > 0) {
      return true;
    } else {
      return false;
    }
  },

  setSession(session: Session) {
    localStorage.setItem(CacheKey.USER_SESSION, JSON.stringify(session));
    window.location.reload();
  },

  updateAuthToken(authToken: string) {
    const session = getSession();
    session.authToken = authToken;
    localStorage.setItem(CacheKey.USER_SESSION, JSON.stringify(session));
  },

  getAuthToken: (): string => {
    let session = getSession();
    return session.authToken;
  },

  getRefreshToken: (): string => {
    let session = getSession();
    return session.refreshToken;
  },

  getUid: (): string => {
    let session = getSession();

    return session.userId;
  },

  logout() {
    try {
      localStorage.removeItem(CacheKey.USER_SESSION);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  },
};
