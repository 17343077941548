import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { CandidateGeneralReportRes } from '../../../response/candidate-general-report.response';

export interface PerformanceReportComponentProp {
  candidateGeneralReportRes: CandidateGeneralReportRes;
}

const PerformanceReportComponent: React.FC<PerformanceReportComponentProp> = ({
  candidateGeneralReportRes,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  let progressVal = 80;

  const options = {
    chart: {
      type: 'column',
      height: 100,
      width: 170,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: ['Ja', 'Fa', 'Mr', 'Ap', 'My'],
      lineColor: 'rgba(0, 0, 0, 0)',
      labels: {
        y: 16,
      },
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stack: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.1,
        pointWidth: 24,
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:11px color:#000000 !important;">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}<br/>',
    },
    series: [
      {
        name: '',
        data: [
          {
            name: 'January',
            y: 30,
            color: '#E8F2FF',
          },
          {
            name: 'Febuary',
            y: 20,
            color: '#E8F2FF',
          },
          {
            name: 'March',
            y: 35,
            color: '#E8F2FF',
          },
          {
            name: 'April',
            y: 45,
            color: '#6EEAE3',
          },
          {
            name: 'May',
            y: 10,
            color: '#E8F2FF',
          },
        ],
      },
    ],
  };

  return (
    <div className="d-flex flex-column me-3 mb-lg-2 mb-4">
      <h6 style={{ color: theme.themeSecondary }} className="font-weight-700">
        {t(I18nKey.PERFORMNACE_REPORT_TEXT)}
      </h6>
      <div className="border border-skyblue rounded-16 px-2 py-3 d-flex align-items-baseline flex-column h-88 highlight--div-styles">
        <div className="d-flex align-items-center mb-2">
          <div className="circle--progrssbar">
            <CircularProgressbarWithChildren
              value={progressVal}
              styles={{
                path: { stroke: `rgb(110, 234, 227)` },
                trail: {
                  stroke: '#F5F2F2',
                  strokeLinecap: 'butt',
                  transform: 'rotate(0.25turn)',
                  transformOrigin: 'center center',
                },
              }}
            >
              <div
                style={{
                  fontSize: 12,
                  marginTop: 0,
                  color: theme.themeSecondary,
                }}
              >
                <strong>{progressVal}%</strong>
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <span style={{ color: theme.themeSecondary }} className="mx-3">
            {t(I18nKey.AVG_MARKS_SCORED_TEXT)}
          </span>
        </div>
        <div className="d-flex mt-auto">
          <div className="d-flex flex-column text-start">
            <h4
              style={{ color: theme.themeSecondary }}
              className="font-weight-600 my-1"
            >
              82{' '}
              <span
                style={{ color: theme.themeSecondary }}
                className="ft-14 font-weight-500"
              >
                / 100
              </span>
            </h4>
            <div style={{ color: theme.themeColor }} className="ft-12 mb-2">
              {t(I18nKey.SCORED_PREV_TEST_TEXT)}
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <HighchartsReact highcharts={Highcharts} options={options} />
            <span style={{ color: theme.themeColor }} className="ft-12">
              {t(I18nKey.MONTHLY_ACTIVITY_TEXT)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceReportComponent;
