import { TestSearchReq, TestTokenReq } from '../request/test.request';
import { TestInfoRes, TestTokenRes } from '../response/test.response';
import httpClient from '../util/http-client';

export const TestService = {
  getAccessToken: async (
    testTokenReq: TestTokenReq,
  ): Promise<(TestTokenRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/test/access-token', testTokenReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  search: async (
    testSearchReq: TestSearchReq,
  ): Promise<(TestInfoRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/test-search', testSearchReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  ongoingTest: async (): Promise<(TestInfoRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/ongoing-test');
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
