export enum I18nKey {
  DONOT_WAIT_LEARN_TEXT = 'donot_wait_learn',
  GRAB_OPPORTUNITY_NOW_TEXT = 'grab_opportunity_now',
  STUDENT_LOGIN_TEXT = 'student_login',
  STUDENT_ID_TEXT = 'student_id',
  ENTER_YOUR_ID_TEXT = 'enter_your_id',
  PASSWORD_KEY_TEXT = 'password',
  ENTER_YOUR_PASSCODE_TEXT = 'enter_your_passcode',
  LOGIN_TEXT = 'login',
  HOME_TEXT = 'home_key',
  REPORT_TEXT = 'report_key',
  TESTS_TEXT = 'tests_key',
  MATERIALS_TEXT = 'materials_key',
  ASSIGNMENT_TEXT = 'assignment_key',
  NEW_TEXT = 'new_key',
  PROFILE_TEXT = 'profile_key',
  POSITION_TEXT = 'position_key',
  CURRENT_RANK_TEXT = 'current_rank_key',
  RECENTLY_OPNED_TEXT = 'recently_opened',
  RESUME_TEXT = 'resume_key',
  MIN_LEFT_TEXT = 'min_left',
  COMPLETED_TEXT = 'completed_key',
  PROGRESS_TEXT = 'progress_key',
  HOURS_SPEND_TEXT = 'hours_spend',
  HRS_TEXT = 'hrs_key',
  DAILY_ACTIVITY_TEXT = 'daily_activity',
  PERFORMNACE_REPORT_TEXT = 'performance_report',
  AVG_MARKS_SCORED_TEXT = 'avg_marks_scored',
  SCORED_PREV_TEST_TEXT = 'scored_prev_test',
  MONTHLY_ACTIVITY_TEXT = 'monthly_activity',
  OF_TEXT = 'of_text',
  EXAM_TAKEN_TEXT = 'exam_taken',
  HIGHEST_RANK_TEXT = 'highest_rank',
  UPCOMING_TESTS_TEXT = 'upcoming_tests',
  MINS_TEXT = 'mins',
  MARKS_TEXT = 'marks',
  BEGIN_EXAM_TEXT = 'begin_exam',
  TEST_SCHEDULE_TEXT = 'test_schedule',
  YET_TO_COMPLETE_TEXT = 'yet_to_complete',
  RESULT_TEXT = 'result_key',
  SCORED_TEXT = 'scored_key',
  VIEW_DETAILS_TEXT = 'view_details',
  TEST_SUMMARY_TEXT = 'test_summary',
  DATE_TEXT = 'date_key',
  TIMING_TEXT = 'timings_key',
  DURATION_TEXT = 'duration_key',
  TIME_TAKEN_TEXT = 'time_taken_key',
  MODE_TEXT = 'mode_key',
  YOUR_TEST_RESULT_KEY = 'your_test_result',
  TOTAL_TEXT = 'total_key',
  ATTEMPTED_TEXT = 'attempted_key',
  PERFORMANCE_CHART_TEXT = 'performance_chart',
  ANSWER_KEY_TEXT = 'answer_key',
  Q_NO_TEXT = 'q_no_key',
  QUESTION_KEY = 'question_key',
  OPTION_TEXT = 'options_key',
  SOLUTION_TEXT = 'solution_key',
  MARKED_TEXT = 'marked_key',
  VIEW_TEXT = 'view_key',
  MATH_SCORE_TEXT = 'math_score_key',
  DIFFICULTY_TEXT = 'difficulty_key',
  NAME_TEXT = 'name_key',
  GENDER_TEXT = 'gender_key',
  DOB_TEXT = 'dob_key',
  EMAIL_ID_TEXT = 'email_id',
  PHONE_NO_TEXT = 'phone_no',
  RESIDENTIAL_ADDRESS_TEXT = 'residential_address',
  PINCODE_TEXT = 'pincode_key',
  ENROLLMENTS_DETAILS_TEXT = 'enrollment_details',
  PACKAGE_TEXT = 'package_key',
  FORM_ID_TEXT = 'form_id',
  COURSE_TEXT = 'course_key',
  STREAM_TEXT = 'stream_key',
  CENTER_TEXT = 'center_key',
  DATE_OF_ENROLLMENT_TEXT = 'date_of_enrollment',
  PERFORMANCE_TEXT = 'performance_key',
  MARKS_OBTAINED_AVG_TEXT = 'marks_obtained_avg',
  PREVIOUS_SCORE_TEXT = 'previous_score',
  ASSIGNMENT_TAKEN_TEXT = 'assignment_taken',
  CANTER_EXAM_TAKEN_TEXT = 'center_exam_taken',
  HIGHEST_SCORE_TEXT = 'highest_score',
  LOWEST_SCORE_TEXT = 'lowest_score',
  LAST_SCORE_TEXT = 'last_score',
  PROGRESS_CHART_TEXT = 'progress_chart',
  PHYSICS_TEXT = 'physics',
  AREA_OF_IMPORVMENT_TEXT = 'area_of_imporvment',
  BEST_PERFORMING_AREA_TEXT = 'best_perform_area',
  HIGHEST_TILL_TODAY_TEXT = 'highest_scored_today',
  AVG_SCORE_TEXT = 'avg_score',
  SCORE_LAST_TEST_TEXT = 'score_last_test',
  CHEMISTRY = 'chemistry',
  MATHS = 'maths',
  BIOLOGY = 'biology',
  POWRED_BY_TEXT = 'powered_by',
  SASSY_CBT_COPYRIGHT_TEXT = 'sassy_cbt_copyright',
  NEED_HELP_CONTACT_US_TEXT = 'need_help_contact_us',
}
