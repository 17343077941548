import { Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';

const HeaderMenuComponent = ({ setShow }: any) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { pathname } = useLocation();
  const { isMobile } = useWindow();

  const { openHome, openReport, openTest } = useSSNavigate();

  return (
    <Navbar className="header--wrapper p-0">
      <Nav className={`me-auto ${isMobile ? 'flex-column' : ''} `}>
        <Nav.Link
          onClick={() => {
            openHome();
            if (setShow) {
              setShow(false);
            }
          }}
          active={pathname === '/'}
          style={{ color: theme.themeColor }}
          className={`rounded px-3 py-2 font-weight-600 ${
            isMobile ? 'mb-3' : 'me-3'
          } `}
        >
          {t(I18nKey.HOME_TEXT)}
        </Nav.Link>
        <Nav.Link
          onClick={() => {
            openReport();
            if (setShow) {
              setShow(false);
            }
          }}
          active={pathname.includes('report')}
          style={{ color: theme.themeColor }}
          className={`rounded px-3 py-2 font-weight-600 ${
            isMobile ? 'mb-3' : 'me-3'
          } `}
        >
          {t(I18nKey.REPORT_TEXT)}
        </Nav.Link>
        <Nav.Link
          onClick={() => {
            openTest();
            if (setShow) {
              setShow(false);
            }
          }}
          active={pathname.includes('test')}
          style={{ color: theme.themeColor }}
          className={`rounded px-3 py-2 font-weight-600 ${
            isMobile ? 'mb-3' : 'me-3'
          } `}
        >
          {t(I18nKey.TESTS_TEXT)}
        </Nav.Link>
        {/* <Nav.Link
                                href="#materials"
                                style={{ color: theme.themeColor }}
                                className="rounded px-3 py-2 me-3 font-weight-600"
                            >
                                {t(I18nKey.MATERIALS_TEXT)}
                            </Nav.Link>
                            <Nav.Link
                                href="#assignment"
                                style={{ color: theme.themeColor }}
                                className="rounded px-3 py-2 me-3 font-weight-600"
                            >
                                {t(I18nKey.ASSIGNMENT_TEXT)}
                            </Nav.Link> */}
      </Nav>
    </Navbar>
  );
};

export default HeaderMenuComponent;
