import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../themes/theme.provider';
import { I18nKey } from '../../i18n/i18n.key';
import ExamRankComponent from './component/exam-rank.component';
import PerformanceReportComponent from './component/performance-report.component';
import PositionComponent from './component/position.component';
import ProgressComponent from './component/progress.component';
import RecentlyOpenComponent from './component/recently-open.component';
import UpcomingTestComponent from './component/upcoming-test.component';
import { useState, useEffect } from 'react';
import { CandidateSearchReq } from '../../request/candidate.request';
import { CandidateRes } from '../../response/candidate.response';
import { CandidateService } from '../../service/candidate.service';
import LoaderComponent from '../shared/loader.component';
import dayjs from 'dayjs';
import { MiniCenter } from '../../model/mini-center.model';
import { MiniExam } from '../../model/mini-exam';
import { MiniProgram } from '../../model/mini-program.model';
import { CandidateGeneralReportService } from '../../service/candidate-general-report.service';
import { CandidateGeneralReportSearchReq } from '../../request/candidate-general-report.request';
import { CandidateGeneralReportRes } from '../../response/candidate-general-report.response';

const HomePage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [candidateRes, setCandidateRes] = useState<CandidateRes>({} as any);
  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(true);

  const [candidateGeneralReportRes, setCandidateGeneralReportRes] =
    useState<CandidateGeneralReportRes>();

  const getGeneralReport = async () => {
    const [data, error] = await CandidateGeneralReportService.search({
      itemsPerPage: 20,
      pageNumber: 1,
    } as CandidateGeneralReportSearchReq);
    if (error) {
    } else {
      if (data && data.length > 0) {
        setCandidateGeneralReportRes(data[0]);
      }
    }
    setReportLoading(false);
  };

  const getCandidate = async () => {
    const [data, error] = await CandidateService.config();
    if (error) {
    } else {
      setCandidateRes(data.candidate);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCandidate();
    getGeneralReport();
  }, []);

  return (
    <>
      <section className="px-3">
        {loading ? (
          <LoaderComponent />
        ) : (
          <div className="d-flex flex-lg-row flex-column py-3 justify-content-between">
            <div className="d-flex flex-column me-3 mb-lg-2 mb-4">
              <h6
                style={{ color: theme.themeSecondary }}
                className="font-weight-700"
              >
                {t(I18nKey.PROFILE_TEXT)}
              </h6>

              <div className="border border-skyblue rounded-16 px-2 py-3 d-flex align-items-lg-start align-items-center h-88 flex-lg-row flex-column highlight--div-styles">
                <div className="d-flex mb-1 flex-column mb-lg-0 mb-3">
                  <span className="rounded-circle profile--icon mb-2">
                    <img src="/images/profile.svg" alt="" />
                  </span>
                  {/* <span className="bg-gray rounded text-nero px-2 py-1 ft-12">
                    @{candidateRes.enrollmentId}
                  </span> */}
                </div>
                <div className="d-flex flex-column ms-lg-2 align-items-lg-baseline align-items-center h-100">
                  <div className="px-4">
                    <div
                      style={{ color: theme.themeSecondary }}
                      className="font-weight-600 ft-14 mb-1"
                    >
                      {candidateRes.candidateInfo?.name}
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <img src="/images/female-icon.svg" height="16" alt="" />
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="mx-1 ft-12"
                      >
                        {candidateRes.candidateInfo?.gender},{' '}
                        {`${dayjs().diff(
                          dayjs(candidateRes.candidateInfo.dateOfBirth),
                          'year',
                        )} Years Old`}
                      </span>
                    </div>
                  </div>
                  <div className="ps-2 mt-auto">
                    <div className="d-flex align-items-center mb-1">
                      <img src="/images/location.svg" height="15" alt="" />
                      <span className="mx-1 ft-12 text-dimgray">
                        {candidateRes?.centerList
                          ?.map((center: MiniCenter) => {
                            return center.name.en;
                          })
                          .join(',')}
                      </span>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <img src="/images/subject.svg" height="15" alt="" />
                      <span className="mx-1 ft-12 text-dimgray">
                        {candidateRes?.examList
                          ?.map((exam: MiniExam) => {
                            return exam.name.en;
                          })
                          .join(',')}
                      </span>
                    </div>
                    <div className="bg-warning px-3 rounded py-1 my-2">
                      <img src="/images/programme.svg" height="15" alt="" />
                      <span className="ms-2 ft-12 text-nero font-weight-600">
                        {candidateRes?.programList
                          ?.map((program: MiniProgram) => {
                            return program.name.en;
                          })
                          .join(',')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {reportLoading ? (
              <LoaderComponent />
            ) : (
              <>
                {candidateGeneralReportRes ? (
                  <>
                    <PositionComponent
                      candidateGeneralReportRes={candidateGeneralReportRes}
                    />
                    <ProgressComponent
                      candidateGeneralReportRes={candidateGeneralReportRes}
                    />
                    <PerformanceReportComponent
                      candidateGeneralReportRes={candidateGeneralReportRes}
                    />
                    <ExamRankComponent
                      candidateGeneralReportRes={candidateGeneralReportRes}
                    />
                  </>
                ) : (
                  <h6>No Report found please attempt any test</h6>
                )}
              </>
            )}
          </div>
        )}

        <Row className="my-2 mx-0">
          <Col className="px-0 me-3">
            <RecentlyOpenComponent />
          </Col>
          <Col className="px-0">
            <UpcomingTestComponent />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default HomePage;
