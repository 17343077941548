import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import { CandidateGeneralReportRes } from '../../../response/candidate-general-report.response';

export interface PositionComponentProp {
  candidateGeneralReportRes: CandidateGeneralReportRes;
}

const PositionComponent: React.FC<PositionComponentProp> = ({
  candidateGeneralReportRes,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { isMobile } = useWindow();

  return (
    <div className="d-flex flex-column me-3 mb-lg-2 mb-4">
      <h6 style={{ color: theme.themeSecondary }} className="font-weight-700">
        {t(I18nKey.POSITION_TEXT)}
      </h6>
      <div
        className={`border border-skyblue rounded-16 px-2 py-3 d-flex h-88 highlight--div-styles ${
          isMobile
            ? 'flex-row align-items-center justify-content-between'
            : ' flex-column align-items-baseline '
        } `}
      >
        <img
          src="/images/book-bulb.svg"
          height="50"
          className="mb-md-2 mb-0"
          alt=""
        />
        <div className="d-flex flex-column">
          <h4
            style={{ color: theme.themeSecondary }}
            className="font-weight-600 mt-1 mb-0"
          >
            {candidateGeneralReportRes?.currentRank?.rank}
          </h4>
          <div style={{ color: theme.themeColor }} className="mt-1">
            {t(I18nKey.CURRENT_RANK_TEXT)}
          </div>
        </div>
        <div className="bg-info px-3 rounded py-1 my-2 ft-12 text-white font-weight-600 mt-auto">
          28 last week
        </div>
      </div>
    </div>
  );
};

export default PositionComponent;
