import { useEffect, useRef, useState } from 'react';

const checkForMobile = (): boolean => {
  return (window as any).innerWidth <= 992;
};

export const useWindow = () => {
  const [isMobile, setIsMobile] = useState(checkForMobile());
  const resizeTimer = useRef<any>();

  const updateWindowSize = () => {
    if (resizeTimer.current) {
      clearTimeout(resizeTimer.current);
    }
    resizeTimer.current = setTimeout(() => {
      setIsMobile(checkForMobile());
    }, 500);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize, false);
    return () => {
      window.removeEventListener('resize', updateWindowSize, false);
    };
  }, []);

  return { isMobile };
};
