import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { CandidateRes } from '../../../response/candidate.response';
import dayjs from 'dayjs';

export interface ProfileDetailsComponentProp {
  candidateRes: CandidateRes;
}

const ProfileDetailsComponent: React.FC<ProfileDetailsComponentProp> = ({
  candidateRes,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <div className="d-flex flex-column h-100 me-md-3 mb-md-0 mb-4">
      <h6
        style={{ color: theme.themeSecondary }}
        className="font-weight-700 mb-3"
      >
        <img src="/images/user-profile.svg" height={48} alt="" />
        <span className="mx-2">{t(I18nKey.PROFILE_TEXT)}</span>
      </h6>
      <div className="border border-skyblue rounded-16 py-3 px-4 font-weight-600">
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.NAME_TEXT)}:
          </Col>
          <Col
            md={10}
            xs={8}
            style={{ color: theme.themeSecondary }}
            className="text-truncate"
          >
            {candidateRes.candidateInfo?.name}
          </Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.GENDER_TEXT)}:
          </Col>
          <Col md={10} xs={8} style={{ color: theme.themeSecondary }}>
            {candidateRes.candidateInfo?.gender}
          </Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.DOB_TEXT)}:
          </Col>
          <Col md={10} xs={8} style={{ color: theme.themeSecondary }}>
            {dayjs(candidateRes.candidateInfo.dateOfBirth).format(
              'DD - MM - YYYY',
            )}
          </Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.EMAIL_ID_TEXT)}:
          </Col>
          <Col
            md={10}
            xs={8}
            style={{ color: theme.themeSecondary }}
            className="text-truncate"
          >
            {candidateRes.candidateInfo?.email}
          </Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.PHONE_NO_TEXT)}:
          </Col>
          <Col md={10} xs={8} style={{ color: theme.themeSecondary }}>
            +91 {candidateRes.candidateInfo?.phone}
          </Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.RESIDENTIAL_ADDRESS_TEXT)}:
          </Col>
          <Col
            md={10}
            xs={8}
            style={{ color: theme.themeSecondary }}
          >{`${candidateRes.address?.lineOne}, ${candidateRes.address?.lineTwo}, ${candidateRes.address?.city}, ${candidateRes.address?.state}`}</Col>
        </Row>
        <Row className="ft-14 mb-3 align-items-center">
          <Col md={2} xs={4} style={{ color: theme.themeColor }}>
            {t(I18nKey.PINCODE_TEXT)}:
          </Col>
          <Col md={10} xs={8} style={{ color: theme.themeSecondary }}>
            {candidateRes.address?.pinCode}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProfileDetailsComponent;
