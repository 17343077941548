import { Button, ListGroup, ProgressBar, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import YetCompleteComponent from '../../shared/yet-complete.component';
import { useState, useEffect } from 'react';
import { TestService } from '../../../service/test.service';
import LoaderComponent from '../../shared/loader.component';

const RecentlyOpenComponent = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { isMobile } = useWindow();

  const [loading, setLoading] = useState(true);

  const [testInfoList, setTestInfoList] = useState([]);

  const findUpcomingTest = async () => {
    const [data, error] = await TestService.ongoingTest();
    if (error) {
    } else {
      setTestInfoList(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    findUpcomingTest();
  }, []);

  return (
    <div
      className={`bg-light-500 p-3 rounded-16 ${
        isMobile ? 'py-3 px-1 mb-3' : 'p-3'
      } `}
    >
      <h6
        style={{ color: theme.themeSecondary }}
        className="font-weight-700 mb-3"
      >
        {t(I18nKey.RECENTLY_OPNED_TEXT)}
      </h6>
      {loading ? (
        <LoaderComponent />
      ) : (
        <YetCompleteComponent testInfoList={testInfoList} />
      )}
    </div>
  );
};

export default RecentlyOpenComponent;
