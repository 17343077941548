import { createBrowserRouter } from 'react-router-dom';
import { SecureRoute } from '../security/secure.route';
import MainHolder from '../page/main.holder';
import AuthPage from '../page/auth/auth.page';
import HomePage from '../page/home/home.page';
import TestDetailPage from '../page/test-detail/test-detail.page';
import TestPage from '../page/test/test.page';
import ReportPage from '../page/report/report.page';
import ProfilePageComponent from '../page/profile/profile-page.component';
import NoPlanPage from '../page/no-plan/no-plan.page';

export const mainRoute = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthPage />,
    children: [],
  },
  {
    path: '/no-plan-found',
    element: <SecureRoute element={<NoPlanPage />} />,
    children: [],
  },
  {
    element: <MainHolder />,
    children: [
      {
        path: '',
        element: <SecureRoute element={<HomePage />} />,
        children: [],
      },
      {
        path: '/test',
        element: <SecureRoute element={<TestPage />} />,
        children: [],
      },
      {
        path: '/report',
        element: <SecureRoute element={<ReportPage />} />,
        children: [],
      },
      {
        path: '/profile',
        element: <SecureRoute element={<ProfilePageComponent />} />,
        children: [],
      },
      {
        path: '/test/:id',
        element: <SecureRoute element={<TestDetailPage />} />,
        children: [],
      },
    ],
  },
  {
    path: '*',
    element: <h1>Page not found</h1>,
  },
]);
