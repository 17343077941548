import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import {
  SubjectAnalysis,
  TopicAnalysis,
} from '../../../response/candidate-general-report.response';

HighchartsMore(Highcharts);

export interface SubjectPerformanceChartComponentProp {
  title: string;
  iconName: string;
  topicAnalysisList: TopicAnalysis[];
}

const SubjectPerformanceChartComponent: React.FC<
  SubjectPerformanceChartComponentProp
> = ({ title, iconName, topicAnalysisList }) => {
  const options = {
    chart: {
      polar: true,
      type: 'line',
      backgroundColor: 'transparent',
      height: 350,
    },
    title: {
      text: '',
      x: 0,
    },

    pane: {
      size: '75%',
    },

    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      categories: topicAnalysisList?.map((topicAnalysis: TopicAnalysis) => {
        return topicAnalysis.topic.name.en;
      }),
      tickmarkPlacement: 'on',
      lineWidth: 0,
    },

    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      labels: {
        enabled: false,
      },
      min: 0,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 1, y1: 0, x2: 1, y2: 1 },
          stops: [
            [0, 'rgba(102, 176, 240, 1)'],
            [1, 'rgba(102, 176, 240, 0)'],
          ],
        },
      },
    },

    tooltip: {
      shared: true,
      pointFormat:
        '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b></span>',
    },

    series: [
      {
        name: '',
        data: topicAnalysisList?.map((topicAnalysis: TopicAnalysis) => {
          return topicAnalysis.level * 100;
        }),
        pointPlacement: 'on',
        color: '#66B0F0',
        opacity: '0.8',
        type: 'area',
      },
    ],
  };

  return (
    <div className="">
      <div className="d-flex align-items-center mb-3">
        <span className="p-2 bg-white">
          <img src={`/images/${iconName}`} alt="" />
        </span>

        <h6 className="font-weight-700 mb-0 mx-3 text-nero">{title}</h6>
      </div>

      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default SubjectPerformanceChartComponent;
