import { Col, Row } from 'react-bootstrap';
import ResultComponent from './component/result.component';
import TestScheduleComponent from './component/test-schedule.component';
import YetToCompleteTestComponent from './component/yet-to-complete-test.component';

const TestPage = () => {
  return (
    <Row className="py-2 lg:px-2 lg:mx-4 mx-0" style={{ maxWidth: '100%' }}>
      <Col md={4}>
        <TestScheduleComponent />
      </Col>
      <Col md={4}>
        <ResultComponent />
      </Col>
      <Col md={4}>
        <YetToCompleteTestComponent />
      </Col>
    </Row>
  );
};

export default TestPage;
