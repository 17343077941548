import { CandidateTestReportSearchReq } from '../request/candidate-test-report.request';
import {
  CandidateTestReportInfoRes,
  CandidateTestReportRes,
} from '../response/candidate-test-report.response';
import httpClient from '../util/http-client';

export const CandidateTestReportService = {
  search: async (
    candidateTestReportSearchReq: CandidateTestReportSearchReq,
  ): Promise<(CandidateTestReportInfoRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(
        '/candidate-test-report-search',
        candidateTestReportSearchReq,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  get: async (id: string): Promise<(CandidateTestReportRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/candidate-test-report/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
