import { Spinner } from 'react-bootstrap';

const LoaderComponent = () => {
  return (
    <Spinner
      style={{
        marginTop: 48,
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      animation="grow"
    />
  );
};

export default LoaderComponent;
