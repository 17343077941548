import {
  AuthTokenReq,
  SendOtpReq,
  SignInReq,
  ValidateOtpReq,
} from '../request/auth.request';
import { ForgotPassReq } from '../request/forgot-password.request';
import { ReSetPassReq } from '../request/reset.request';
import {
  AuthTokenRes,
  SendOtpRes,
  SignInRes,
  ValidateOtpRes,
} from '../response/auth.response';
import { SecurityManager } from '../security/security-manager';
import httpClient from '../util/http-client';

export const AuthService = {
  sendOtp: async (sendOtpReq: SendOtpReq): Promise<(SendOtpRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/send-otp', sendOtpReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  validateOtp: async (
    validateOtpReq: ValidateOtpReq,
  ): Promise<(ValidateOtpRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/validate-otp', validateOtpReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  signIn: async (signInReq: SignInReq): Promise<(SignInRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/sign-in', signInReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getAuthToken: async (): Promise<(AuthTokenRes | any)[]> => {
    let data;
    let err;
    try {
      const authTokenReq = {
        userId: SecurityManager.getUid(),
        refreshToken: SecurityManager.getRefreshToken(),
      } as AuthTokenReq;
      const res = await httpClient.post('/auth-token', authTokenReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  logOut: async (): Promise<any[]> => {
    let data;
    let err;
    try {
      const authTokenReq = {
        userId: SecurityManager.getUid(),
        refreshToken: SecurityManager.getRefreshToken(),
        authToken: SecurityManager.getAuthToken(),
      } as AuthTokenReq;
      const res = await httpClient.post('/log-out', authTokenReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  sendForgotPassLink: async (
    forgotPassReq: ForgotPassReq,
  ): Promise<(SendOtpRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(
        '/send-forgot-pass-link',
        forgotPassReq,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  reSetPass: async (
    reSetPassReq: ReSetPassReq,
  ): Promise<(ValidateOtpRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/re-set-pass', reSetPassReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
