import { ListGroup, Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n/i18n.key';
import { useTheme } from '../../themes/theme.provider';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { TestInfoRes } from '../../response/test.response';
import React from 'react';

export interface YetCompleteComponentProp {
  testInfoList: TestInfoRes[];
}

const YetCompleteComponent: React.FC<YetCompleteComponentProp> = ({
  testInfoList,
}) => {
  const { t } = useTranslation();
  const { startTest } = useSSNavigate();

  return (
    <>
      {testInfoList.length > 0 ? (
        <ListGroup as="ul" className="highlight--styles">
          {testInfoList.map((testInfo: TestInfoRes) => {
            return (
              <ListGroup.Item
                as="li"
                className="px-3 py-2 text-nero bg-transparent border-0"
              >
                <div className="d-flex align-items-center">
                  <img src="/images/img-1.svg" height="35" alt="" />
                  <span className="mx-3">{testInfo.name.en}</span>
                  <Button
                    onClick={() => {
                      startTest(testInfo.id);
                    }}
                    variant="tertiary"
                    className="px-3 py-1 ft-12 ms-auto font-weight-600"
                  >
                    <img
                      src="/images/play.svg"
                      height="12"
                      className="me-2"
                      alt=""
                    />
                    {t(I18nKey.RESUME_TEXT)}
                  </Button>
                </div>
                <div className="mt-2 d-flex align-items-center progress--details">
                  <img src="/images/info-icon.svg" height="24" alt="" />
                  <div className="ft-12 mx-2">
                    {((testInfo.duration - testInfo.timeTaken) / 60).toFixed(0)}
                    <span className="text-dimgray mx-1">
                      {t(I18nKey.MIN_LEFT_TEXT)}
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-grow-1">
                    <div className="w-100 thin--progress">
                      <ProgressBar
                        now={(testInfo.timeTaken / testInfo.duration) * 100}
                      />
                    </div>
                    <div className="ft-12 ms-2">
                      {((testInfo.timeTaken / testInfo.duration) * 100).toFixed(
                        0,
                      )}
                      %
                      <span className="text-dimgray ms-2">
                        {t(I18nKey.COMPLETED_TEXT)}
                      </span>
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : (
        <h6>Not in progress Test Found!</h6>
      )}
    </>
  );
};

export default YetCompleteComponent;
