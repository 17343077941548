import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';

export interface PerformanceChartComponentProp {
  isSubject: boolean | true;
  highestMarks: number;
  yourMarks: number;
  avgMarks: number;
}
const PerformanceChartComponent: React.FC<PerformanceChartComponentProp> = ({
  isSubject,
  highestMarks,
  yourMarks,
  avgMarks,
}) => {
  const { t } = useTranslation();

  const options = {
    chart: {
      type: 'column',
      width: 310,
      height: 205,
    },
    title: {
      text: isSubject ? '' : t(I18nKey.PERFORMANCE_CHART_TEXT),
      style: {
        fontSize: '16px',
      },
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      title: '',
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 0,
      y: 80,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stack: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.2,
        pointWidth: 28,
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:11px color:#000000 !important;">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}<br/>',
    },
    series: [
      {
        name: 'Topper Score',
        data: [
          {
            y: highestMarks,
            color: '#BACAFF',
          },
        ],
      },
      {
        name: 'Your Score',
        data: [
          {
            y: yourMarks,
            color: '#9747FF',
          },
        ],
      },
      {
        name: 'Average Score',
        data: [
          {
            y: avgMarks,
            color: '#3FB1B5',
          },
        ],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PerformanceChartComponent;
