import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import validator from 'validator';
import empty from 'is-empty';
import { Session } from '../../../model/session.model';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { SignInReq } from '../../../request/auth.request';
import { SecurityManager } from '../../../security/security-manager';
import { AuthService } from '../../../service/auth.service';
import { AuthPageState, AuthPageStateProp } from '../auth.page';
import { ForgotPassReq } from '../../../request/forgot-password.request';

const ForgotPassComponent: React.FC<AuthPageStateProp> = ({
  updatePageState,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const [errors, setErrors] = useState({
    phone: '',
  });
  const [phone, setPhone] = useState('');

  const sendLink = async () => {
    const [data, err] = await AuthService.sendForgotPassLink({
      phone: phone,
    } as ForgotPassReq);

    if (err) {
      const _errors: any = {};
      _errors.id = 'Please enter valid ID';
      _errors.password = 'Please enter valid password';
      setErrors(_errors);
      setValidated(false);
      setLoading(false);
    } else {
      updatePageState(AuthPageState.RESET_PASS);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (!validator.isLength(phone, { min: 10, max: 10 })) {
      _errors.id = 'Please enter valid Phone';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      sendLink();
    }
  };

  const formStyle = {
    width: '336px',
    maxWidth: '336px',
  };

  return (
    <div
      className="rounded-16 bg-white p-4 ft-14 ms-lg-5 mt-lg-0 mt-5 login--wrapper text-nero z-1 max-w-332"
      style={formStyle}
    >
      <h5 className="mb-4 text-nero">Reset Password</h5>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <p>
            Type your Phone Number associated with your account and we will send
            your link there with instructions to recover your account.
          </p>
          <Form.Group controlId="id" className="">
            <InputGroup hasValidation className="mb-3">
              <Form.Control
                required
                type="text"
                value={phone}
                isInvalid={!!errors.phone}
                onChange={(e) => setPhone(e.target.value)}
                className={`ft-14 px-2 shadow-none border ${
                  errors.phone ? 'border-danger' : ''
                } `}
                placeholder={'Enter your Phone No.'}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid Phone
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <div className="text-start mt-2">
            <span className="mb-2">Have user id and pass?</span>
            <Button
              style={{ backgroundColor: '#FFFFFF', borderWidth: 0 }}
              onClick={() => updatePageState(AuthPageState.NORMAL)}
            >
              log in
            </Button>
          </div>
          <div className="text-center mt-4">
            <Button
              variant="primary"
              type="submit"
              className="text-white ft-14 font-weight-600"
            >
              Send Link
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default ForgotPassComponent;
