import { CandidateGeneralReportSearchReq } from '../request/candidate-general-report.request';
import { CandidateGeneralReportRes } from '../response/candidate-general-report.response';
import httpClient from '../util/http-client';

export const CandidateGeneralReportService = {
  search: async (
    candidateGeneralReportSearchReq: CandidateGeneralReportSearchReq,
  ): Promise<(CandidateGeneralReportRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(
        '/candidate-general-report-search',
        candidateGeneralReportSearchReq,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
