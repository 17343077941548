import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import { CandidateGeneralReportRes } from '../../../response/candidate-general-report.response';

export interface ExamRankComponentProp {
  candidateGeneralReportRes: CandidateGeneralReportRes;
}

const ExamRankComponent: React.FC<ExamRankComponentProp> = ({
  candidateGeneralReportRes,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { isMobile } = useWindow();

  return (
    <div className="d-flex flex-column me-1 pt-2 mb-lg-2 mb-4">
      <div className="border border-skyblue rounded-16 p-2 d-flex align-items-center my-3 flex-grow-1 highlight--div-styles">
        <div className={`my-3 bg-white d-flex align-items-center p-3 rounded`}>
          <div className={`d-flex align-items-center`}>
            <h6>
              <strong>{candidateGeneralReportRes.testTakenList?.length}</strong>{' '}
              {t(I18nKey.CANTER_EXAM_TAKEN_TEXT)}
            </h6>
          </div>
        </div>
      </div>

      <div className="border border-skyblue rounded-16 px-2 py-4 d-flex align-items-center flex-grow-1 highlight--div-styles">
        <div className="d-flex align-items-center">
          <h6
            style={{ color: theme.themeSecondary }}
            className="font-weight-700 m-0"
          >
            {candidateGeneralReportRes.highestRank?.rank
              ? candidateGeneralReportRes.highestRank?.rank
              : '-'}
          </h6>
          <span
            style={{ color: theme.themeSecondary }}
            className="ft-12 font-weight-400 ms-2"
          >
            {t(I18nKey.OF_TEXT)}{' '}
            {candidateGeneralReportRes.highestRank?.totalCandidate
              ? candidateGeneralReportRes.highestRank?.totalCandidate
              : '-'}
          </span>
        </div>
        <div className={`ft-12 ${isMobile ? 'ms-auto' : 'ms-2'}`}>
          {t(I18nKey.HIGHEST_RANK_TEXT)}
        </div>
      </div>
    </div>
  );
};

export default ExamRankComponent;
