import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n/i18n.key';
import { useTheme } from '../../themes/theme.provider';
import TestResultComponent from './component/test-result.component';
import TestSummaryComponent from './component/test-summary.component';
import { useParams } from 'react-router';
import {
  CandidateTestReportRes,
  QuestionReport,
  SectionAnalysis,
} from '../../response/candidate-test-report.response';
import {
  SectionSummary,
  TestSummaryReportRes,
} from '../../response/test-summary-report.response';
import { CandidateTestReportService } from '../../service/candidate-test-report.service';
import { TestSummaryReportService } from '../../service/test-summary-report.service';
import { TestSummaryReportSearchReq } from '../../request/test-summary-report.request';
import SectionScoreChartComponent from './component/section-score-chart.component';
import { QuestionOption } from '../../response/question.response';
import { CKTextView } from '../../view/ck-text.view';

const TestDetailPage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  let { id } = useParams();

  const [loading, setLoading] = useState(true);

  const [candidateTestReportRes, setCandidateTestReportRes] =
    useState<CandidateTestReportRes>({} as any);
  const [testSummaryReportRes, setTestSummaryReportRes] =
    useState<TestSummaryReportRes>({} as any);

  const [selectedSectionAnalysis, setSelectedSectionAnalysis] =
    useState<SectionAnalysis>();

  const getTestReport = async () => {
    const [data, error] = await CandidateTestReportService.get(id!);
    if (error) {
    } else {
      setCandidateTestReportRes(data);
      const [_data, error] = await TestSummaryReportService.search({
        testIdList: [data.test.id],
        pageNumber: 1,
        itemsPerPage: 20,
      } as TestSummaryReportSearchReq);
      if (error) {
      } else {
        setTestSummaryReportRes(_data[0]);
        setSelectedSectionAnalysis(data.sectionAnalysisList[0]);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getTestReport();
  }, []);

  const sectionSummaryList = testSummaryReportRes.sectionSummaryList?.filter(
    (sectionSummary: SectionSummary) => {
      return sectionSummary.id === selectedSectionAnalysis?.id;
    },
  );

  return (
    <>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <div className="lg:px-2 lg:py-2 p-1">
          <Breadcrumb className="my-3 px-2">
            <Breadcrumb.Item
              href="/test"
              className="ft-12"
              style={{ color: theme.themeSecondary }}
            >
              {t(I18nKey.TESTS_TEXT)}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="ft-12"
              style={{ color: theme.themeSecondary }}
              active
            >
              IIT Mock Test results II
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row className="px-2">
            <Col lg={3} xs={12} className="mb-lg-0 mb-4">
              <TestSummaryComponent
                testAnalysis={candidateTestReportRes.testAnalysis}
                mode={candidateTestReportRes.test.mode}
                createdOn={candidateTestReportRes.createdOn}
              />
            </Col>
            <Col lg={9} xs={12} className="mb-lg-0 mb-4">
              <TestResultComponent
                candidateTestReportRes={candidateTestReportRes}
                testSummaryReportRes={testSummaryReportRes}
              />
            </Col>
          </Row>
          <div className="py-4 px-2">
            <div className="d-flex align-items-center">
              <h6
                style={{ color: theme.themeSecondary }}
                className="font-weight-700 ft-14 m-0"
              >
                {t(I18nKey.ANSWER_KEY_TEXT)}
              </h6>
              <div className="ms-auto d-flex align-items-center">
                <span style={{ color: theme.themeColor }} className="mx-3">
                  <strong
                    className="mx-1"
                    style={{ color: theme.themeSecondary }}
                  >
                    {selectedSectionAnalysis?.question.attempted}
                  </strong>
                  {t(I18nKey.OF_TEXT)} {selectedSectionAnalysis?.question.total}{' '}
                  {t(I18nKey.ATTEMPTED_TEXT)}
                </span>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    id="math-dropdown"
                    className="text-white"
                  >
                    {selectedSectionAnalysis?.name.en}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {candidateTestReportRes.sectionAnalysisList.map(
                      (sectionAnalysis: SectionAnalysis) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedSectionAnalysis(sectionAnalysis);
                            }}
                          >
                            {sectionAnalysis.name.en}
                          </Dropdown.Item>
                        );
                      },
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="border border-skyblue rounded-16 px-3 py-3 d-flex align-items-baseline flex-md-row flex-column my-3">
              <Col lg={9} md={7} xs={12} className="mb-lg-0 mb-5">
                <div className="overflow-auto text-center position-relative">
                  <Row
                    className="flex-nowrap mx-0 answer-header--row"
                    style={{ width: '100%' }}
                  >
                    <Col
                      xs={1}
                      style={{ background: theme.themePrimary }}
                      className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center answer--table-header"
                    >
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="font-weight-600"
                      >
                        {t(I18nKey.Q_NO_TEXT)}
                      </span>
                    </Col>
                    <Col
                      xs={1}
                      style={{ background: theme.themePrimary }}
                      className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center answer--table-header"
                    >
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="font-weight-600"
                      >
                        {t(I18nKey.SCORED_TEXT)}
                      </span>
                    </Col>
                    <Col
                      xs={4}
                      style={{ background: theme.themePrimary }}
                      className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center answer--table-header"
                    >
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="font-weight-600"
                      >
                        Correct Answer
                      </span>
                    </Col>
                    <Col
                      xs={4}
                      style={{ background: theme.themePrimary }}
                      className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center answer--table-header"
                    >
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="font-weight-600"
                      >
                        Your Answer
                      </span>
                    </Col>

                    <Col
                      xs={2}
                      style={{ background: theme.themePrimary }}
                      className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center answer--table-header"
                    >
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="font-weight-600"
                      >
                        Details
                      </span>
                    </Col>
                    {/* <Col
                      xs={1}
                      style={{ background: theme.themePrimary }}
                      className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center answer--table-header"
                    >
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="font-weight-600"
                      >
                        {t(I18nKey.Q_NO_TEXT)}
                      </span>
                    </Col>
                    <Col
                      xs={5}
                      style={{ background: theme.themePrimary }}
                      className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center answer--table-header"
                    >
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="font-weight-600"
                      >
                        {t(I18nKey.QUESTION_KEY)}
                      </span>
                    </Col>
                    <Col
                      xs={5}
                      style={{ background: theme.themePrimary }}
                      className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center answer--table-header"
                    >
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="font-weight-600"
                      >
                        {t(I18nKey.OPTION_TEXT)}
                      </span>
                    </Col>
                    <Col
                      xs={1}
                      style={{ background: theme.themePrimary }}
                      className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center answer--table-header"
                    >
                      <span
                        style={{ color: theme.themeSecondary }}
                        className="font-weight-600"
                      >
                        {t(I18nKey.SCORED_TEXT)}
                      </span>
                    </Col> */}
                  </Row>
                  <div className="answer-key--wrapper">
                    {candidateTestReportRes.questionReportList
                      .filter((question: QuestionReport, index: number) => {
                        return (
                          question.sectionId === selectedSectionAnalysis?.id
                        );
                      })
                      .map((question: QuestionReport, index: number) => {
                        return (
                          <Row className="flex-nowrap mx-0 answer-body--row">
                            <Col
                              xs={1}
                              className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center"
                            >
                              <span style={{ color: theme.themeSecondary }}>
                                {index + 1}.
                              </span>
                            </Col>
                            <Col
                              xs={1}
                              className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center"
                            >
                              <span
                                style={{ color: theme.themeSecondary }}
                                className={`font-weight-600 ${
                                  question.score.scored > 0
                                    ? 'text-green'
                                    : 'text-danger'
                                }`}
                              >
                                {question.score.scored}
                              </span>
                            </Col>
                            <Col
                              xs={4}
                              className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center"
                            >
                              <span
                                style={{ color: theme.themeSecondary }}
                                className="font-weight-600 text-green"
                              >
                                {question.question.optionList && (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: question.question.optionList
                                        .filter((option) => {
                                          return option.correct;
                                        })
                                        .map((option) => {
                                          return option.value.en.trim();
                                        })
                                        .toString() as any,
                                    }}
                                  ></span>
                                )}
                                {question.question.answerList && (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        question.question.answerList.toString() as any,
                                    }}
                                  ></span>
                                )}
                              </span>
                            </Col>
                            <Col
                              xs={4}
                              className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center"
                            >
                              <span
                                style={{ color: theme.themeSecondary }}
                                className={`font-weight-600 ${
                                  question.score.scored > 0
                                    ? 'text-green'
                                    : 'text-danger'
                                }`}
                              >
                                {question.questionResponse.optionList && (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: question.question.optionList
                                        .filter((option) => {
                                          return question.questionResponse.optionList
                                            .filter((item) => item.selected)
                                            .map((item) => item.id)
                                            .includes(option.id);
                                        })
                                        .map((option) => {
                                          return option.value.en.trim();
                                        })
                                        .toString() as any,
                                    }}
                                  ></span>
                                )}
                                {question.questionResponse.candidateAnswer && (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        question.questionResponse.candidateAnswer.toString() as any,
                                    }}
                                  ></span>
                                )}
                              </span>
                            </Col>
                            <Col
                              xs={2}
                              className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center"
                            >
                              <Button style={{ color: theme.themeSecondary }}>
                                Detail
                              </Button>
                            </Col>
                            {/* <Col
                              xs={1}
                              className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center"
                            >
                              <span style={{ color: theme.themeSecondary }}>
                                {index + 1}.
                              </span>
                            </Col>
                            <Col
                              style={{ textAlign: 'left' }}
                              xs={5}
                              className="border-bottom border-light-skyblue py-2 d-flex align-items-center"
                            >
                              <CKTextView
                                initData={question.question.description.en}
                              />
                            </Col>
                            <Col
                              style={{ textAlign: 'left' }}
                              xs={5}
                              className="border-bottom border-light-skyblue py-2 d-flex align-items-center"
                            >
                              {question.question.optionList && (
                                <ol>
                                  {question.question.optionList.map(
                                    (option: QuestionOption) => {
                                      return (
                                        <li>
                                          <span
                                            style={{
                                              color: theme.themeSecondary,
                                              background: `${
                                                option.correct
                                                  ? question.questionResponse
                                                      .attempted
                                                    ? question.score.scored > 0
                                                      ? 'green'
                                                      : 'red'
                                                    : 'yellow'
                                                  : 'white'
                                              }`,
                                            }}
                                          >
                                            <CKTextView
                                              initData={option.value.en}
                                            />
                                          </span>
                                        </li>
                                      );
                                    },
                                  )}
                                </ol>
                              )}
                              {question.question.answerList && (
                                <ol>
                                  {question.question.answerList.map(
                                    (answer: string) => {
                                      return (
                                        <li>
                                          <CKTextView initData={answer} />
                                        </li>
                                      );
                                    },
                                  )}
                                </ol>
                              )}
                            </Col>
                            <Col
                              xs={1}
                              className="border-bottom border-light-skyblue py-2 d-flex align-items-center justify-content-center"
                            >
                              <span
                                style={{ color: theme.themeSecondary }}
                                className="font-weight-600 text-danger"
                              >
                                {question.score.scored}
                              </span>
                            </Col> */}
                          </Row>
                        );
                      })}
                  </div>
                </div>
              </Col>
              <Col lg={3} md={5} xs={12} className="mb-lg-0 mb-4">
                <SectionScoreChartComponent
                  level={sectionSummaryList[0].level * 100}
                  sectionAnalysis={selectedSectionAnalysis!}
                  highestMarks={sectionSummaryList[0].highestScore}
                  yourMarks={selectedSectionAnalysis!.score.scored}
                  avgMarks={sectionSummaryList[0].averageScore}
                />
              </Col>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TestDetailPage;
