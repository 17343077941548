import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import TestUiComponent from '../../shared/test-ui.component';
import { useEffect, useState } from 'react';
import { TestService } from '../../../service/test.service';
import { TestSearchReq } from '../../../request/test.request';
import { Spinner } from 'react-bootstrap';
import LoaderComponent from '../../shared/loader.component';

const UpcomingTestComponent = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { isMobile } = useWindow();
  const [loading, setLoading] = useState(true);

  const [testInfoList, setTestInfoList] = useState([]);

  const findUpcomingTest = async () => {
    const [data, error] = await TestService.search({
      itemsPerPage: 20,
      pageNumber: 1,
    } as TestSearchReq);
    if (error) {
    } else {
      setTestInfoList(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    findUpcomingTest();
  }, []);

  return (
    <div
      className={`bg-light rounded-16 ${
        isMobile ? 'ms-0 py-3 px-1 mb-3' : 'ms-2 p-3 '
      } `}
    >
      <h6
        style={{ color: theme.themeSecondary }}
        className="font-weight-700 mb-3"
      >
        {t(I18nKey.UPCOMING_TESTS_TEXT)}
        <span className="ft-12 font-weight-500 mx-3">
          {testInfoList.length}
        </span>
      </h6>

      {loading ? (
        <LoaderComponent />
      ) : (
        <TestUiComponent isFromProfilePage={true} testInfoList={testInfoList} />
      )}
    </div>
  );
};

export default UpcomingTestComponent;
