import { TestSummaryReportSearchReq } from '../request/test-summary-report.request';
import { TestSummaryReportRes } from '../response/test-summary-report.response';
import httpClient from '../util/http-client';

export const TestSummaryReportService = {
  search: async (
    testSummaryReportSearchReq: TestSummaryReportSearchReq,
  ): Promise<(TestSummaryReportRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(
        '/test-summary-search',
        testSummaryReportSearchReq,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
