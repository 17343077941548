import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import PerformanceChartComponent from './performance-chart.component';
import { SectionAnalysis } from '../../../response/candidate-test-report.response';

export interface SectionScoreChartComponentProp {
  level: number;
  highestMarks: number;
  yourMarks: number;
  avgMarks: number;
  sectionAnalysis: SectionAnalysis;
}

const SectionScoreChartComponent: React.FC<SectionScoreChartComponentProp> = ({
  level,
  highestMarks,
  yourMarks,
  avgMarks,
  sectionAnalysis,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <div className="d-flex flex-column ms-4">
      <div className="d-flex align-items-center ft-14 pb-1">
        <span
          style={{ color: theme.themeSecondary }}
          className="font-weight-600"
        >
          {sectionAnalysis.name.en} Score
        </span>
        <div className="ms-auto">
          <span style={{ color: theme.themeSecondary }} className="ft-20">
            {sectionAnalysis.score.scored}/
          </span>
          <span style={{ color: theme.themeColor }} className="ft-14">
            {sectionAnalysis.score.full}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center ft-12 mt-1 mb-3">
        <span style={{ color: theme.themeColor }}>
          {t(I18nKey.DIFFICULTY_TEXT)}
        </span>
        <div className="flex-grow-1 math-score--progress mx-2">
          <ProgressBar variant="danger" now={level} visuallyHidden />
        </div>
        <span style={{ color: theme.themeSecondary }}>HIGH</span>
      </div>
      <div className="mt-2">
        <PerformanceChartComponent
          isSubject={true}
          highestMarks={highestMarks}
          yourMarks={yourMarks}
          avgMarks={avgMarks}
        />
      </div>
    </div>
  );
};

export default SectionScoreChartComponent;
